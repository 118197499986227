import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../../../utils/join-url';

const getMonthlyReportList = async (): Promise<
  {
    domain: string;
    license_key: string;
    report_dates: string[];
  }[]
> => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(
      process.env.REACT_APP_API_ENDPOINT!,
      'http/get-monthly-report-list-by-email'
    ),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = response.json();

  if (!response.ok) {
    throw new Error((data as any).message);
  }

  return data;
};

export { getMonthlyReportList };
