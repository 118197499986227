import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../../../utils/join-url';

const upgradePreview = async (body: any) => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/upgrade'),
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    }
  );

  const data = await response.json();

  return data;
};

export { upgradePreview };
