import { create } from 'zustand';
import { TrafficReport } from './monthly-report.api';

type MonthlyReportStore = {
  report: TrafficReport | null;
  setReport: (report: TrafficReport) => void;
};

const useMonthlyReportStore = create<MonthlyReportStore>((set) => ({
  report: null,

  setReport: (report: TrafficReport) => {
    const newReport = { ...report };
    set(() => ({ report: newReport }));
  },
}));

export default useMonthlyReportStore;
