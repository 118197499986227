import { ReactNode, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAccountInfo } from '../AccountInfos/Subscriptions/account-info.api';
import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from '../../features/auth/AuthStore';
import { MINUTE } from '../../consts/time';

const { $crisp } = window;

export default function Layout({
  children,
}: Readonly<{ children: ReactNode }>) {
  const { data: accountData, isError: accountError } = useQuery({
    queryKey: ['accountInfo'],
    queryFn: getAccountInfo,
    staleTime: 10 * MINUTE,
  });

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useEffect(() => {
    if (accountData && !accountData.customers && isAuthenticated) {
      if (!accountError && accountData) {
        let crispData: any = [];

        accountData?.subscriptions.foreach(
          (subscription: any, order_id: string) => {
            let plan = accountData.plans[order_id].name
              ? accountData.plans[order_id].name
              : subscription.plan.nickname;
            let license_key = accountData.metas[order_id].license_key;
            let domains = accountData.metas[order_id].allowed_domains
              .join('\n')
              .replace(/^\n/, '');

            crispData.push([plan, subscription.id]);
            crispData.push([
              `${plan}_Customer`,
              accountData.customers[order_id].id ?? '',
            ]);
            crispData.push([`${plan}_Status`, subscription.status]);
            crispData.push([
              `${plan}_License`,
              license_key.substr(license_key.length - 4),
            ]);
            crispData.push([`${plan}_Domain`, domains]);
          }
        );
        if (crispData?.length) {
          $crisp.push(['set', 'session:data', [crispData]]);
        }
      }
    } else {
      //local cache
    }
  }, [accountData]);

  return (
    <>
      <Navbar />
      {children}
      <Footer />
      <ToastContainer />
    </>
  );
}
