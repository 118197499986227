import { joinUrls } from '../../../utils/join-url';

const applyStripePromoCode = async ({
  promotionCode,
}: {
  promotionCode: string;
}): Promise<StripePromoCode> => {
  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/coupon-offer'),
    {
      method: 'POST',
      body: JSON.stringify({
        promotionCode,
      }),
    }
  );

  const data = await response.json();
  return data;
};

export type StripePromoCode = {
  id: string;
  active: boolean;
  code: string;
  coupon: {
    id: string;
    amount_off: number | null;
    percent_off: number | null;
    valid: boolean;
    duration: string;
  };
  created: number;
};

export { applyStripePromoCode };
