import { Document, Font, Page, Text, View, Image } from '@react-pdf/renderer';
import { AIReport } from './monthly-report.api';
import pdfStyles from './pdfStyle';

Font.register({
  family: 'figtree',
  src: '/fonts/Figtree/static/Figtree-Regular.ttf',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {
      src: '/fonts/Figtree/static/Figtree-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: '/fonts/Figtree/static/Figtree-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: '/fonts/Figtree/static/Figtree-Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: '/fonts/Figtree/static/Figtree-SemiBold.ttf',
      fontStyle: 'normal',
      fontWeight: 600,
    },
  ],
});

const MonthlyReportPDFDocument = ({
  aiReport,
  domain,
  reportDate,
}: {
  aiReport: AIReport;
  domain: string;
  reportDate: string;
}) => {
  const trafficByUtmSourceImage = localStorage.getItem(
    'trafficByUtmSourceImage'
  );

  const trafficByUtmCampaignImage = localStorage.getItem(
    'trafficByUtmCampaignImage'
  );

  const trafficByDeviceTypeImage = localStorage.getItem(
    'trafficByDeviceTypeImage'
  );

  const trafficByBrowserTypeImage = localStorage.getItem(
    'trafficByBrowserTypeImage'
  );

  const trafficByTrafficSourceImage = localStorage.getItem(
    'trafficByTrafficSourceImage'
  );

  const trafficByOrganicSourceImage = localStorage.getItem(
    'trafficByOrganicSourceImage'
  );

  return (
    <Document>
      <Page size='A4' style={pdfStyles.page}>
        <Header reportDate={reportDate} domain={domain} />
        <View style={{ padding: 20 }}>
          <View>
            <Text style={pdfStyles.title}>Executive Summary</Text>
            <Text
              style={{
                fontSize: 14,
                marginBottom: 20,
              }}
            >
              {truncateText(aiReport.executive_summary, 795)}
            </Text>
          </View>

          <View>
            <Text style={pdfStyles.title}>1. Paid Traffic</Text>

            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#737373',
              }}
            >
              Traffic by UTM Source
            </Text>
            <Image src={trafficByUtmSourceImage!} style={{ height: 300 }} />
          </View>
        </View>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size='A4' style={pdfStyles.page}>
        <View style={{ padding: 20 }}>
          <View style={{ marginTop: 20 }}>
            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#737373',
              }}
            >
              Traffic by UTM Campaign
            </Text>
            <Image src={trafficByUtmCampaignImage!} style={{ height: 300 }} />
          </View>

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/green-tick.png'
            label='Thriving'
            text={aiReport.paid.thriving}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/red-cross.png'
            label='Challenges'
            text={aiReport.paid.challenges}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/strategy-icon.png'
            label='Strategies for Improvements'
            text={aiReport.paid.strategies}
            showAsList={true}
            iconWidth={15}
          />
        </View>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size='A4' style={pdfStyles.page}>
        <View style={{ padding: 20 }}>
          <Text style={pdfStyles.title}>2. Device Traffic</Text>
          <View style={{ marginTop: 20 }}>
            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#737373',
              }}
            >
              Traffic by Device Type
            </Text>
            <Image src={trafficByDeviceTypeImage!} style={{ height: 300 }} />
          </View>

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/magnifier-icon.png'
            label='Observations'
            text={aiReport.device.observations}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/strategy-icon.png'
            label='Strategies for Improvements'
            text={aiReport.device.strategies}
            showAsList={true}
            iconWidth={15}
          />
        </View>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size='A4' style={pdfStyles.page}>
        <View style={{ padding: 20 }}>
          <Text style={pdfStyles.title}>3. Browser Traffic</Text>
          <View style={{ marginTop: 20 }}>
            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#737373',
              }}
            >
              Traffic by Browser Type
            </Text>
            <Image src={trafficByBrowserTypeImage!} style={{ height: 300 }} />
          </View>

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/magnifier-icon.png'
            label='Observations'
            text={aiReport.browser.observations}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/strategy-icon.png'
            label='Strategies for Improvements'
            text={aiReport.browser.strategies}
            showAsList={true}
            iconWidth={15}
          />
        </View>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>

      <Page size='A4' style={pdfStyles.page}>
        <View style={{ padding: 20 }}>
          <Text style={pdfStyles.title}>4. Traffic Source</Text>
          <View style={{ marginTop: 20 }}>
            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#737373',
              }}
            >
              Traffic by Traffic Source
            </Text>
            <Image src={trafficByTrafficSourceImage!} style={{ height: 300 }} />
          </View>

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/green-tick.png'
            label='Thriving'
            text={aiReport.traffic_source.thriving}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/red-cross.png'
            label='Observations'
            text={aiReport.traffic_source.challenges}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/strategy-icon.png'
            label='Strategies for Improvements'
            text={aiReport.traffic_source.strategies}
            showAsList={true}
            iconWidth={15}
          />
        </View>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>

      <Page size='A4' style={pdfStyles.page}>
        <View style={{ padding: 20 }}>
          <Text style={pdfStyles.title}>5. Organic Traffic</Text>
          <View style={{ marginTop: 20 }}>
            <Text
              style={{
                fontSize: 14,
                textAlign: 'center',
                color: '#737373',
              }}
            >
              Traffic by Organic Source Type
            </Text>
            <Image src={trafficByOrganicSourceImage!} style={{ height: 300 }} />
          </View>

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/green-tick.png'
            label='Thriving'
            text={aiReport.organic.thriving}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/red-cross.png'
            label='Observations'
            text={aiReport.organic.challenges}
          />

          <PerformanceMetricsSection
            iconSrc='/pdf-icons/strategy-icon.png'
            label='Strategies for Improvements'
            text={aiReport.organic.strategies}
            showAsList={true}
            iconWidth={15}
          />
        </View>
        <Text
          style={pdfStyles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

const Header = ({
  reportDate,
  domain,
}: {
  reportDate: string;
  domain: string;
}) => {
  return (
    <View style={pdfStyles.headerContainer}>
      <View>
        <Image source='/logo.png' style={{ width: 200, height: 105 }} />
      </View>
      <View
        style={{
          height: 105,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Text>Monthly Report</Text>
          <Text style={pdfStyles.date}>
            {new Date(reportDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
            })}
          </Text>
        </View>

        <Text
          style={{
            fontFamily: 'figtree',
            fontSize: 12,
            color: '#737373',
          }}
        >
          {`https://${domain}`}
        </Text>
      </View>
    </View>
  );
};

const PerformanceMetricsSection = ({
  iconSrc,
  label,
  text,
  iconWidth,
  showAsList = false,
}: {
  iconSrc: string;
  label: string;
  text: string | string[];
  iconWidth?: number;
  showAsList?: boolean;
}) => {
  if (Array.isArray(text)) {
    text = text.join(' ');
  }

  const sentences = text
    .replace(/\d+\.\s/gm, '') // removes 1. 2. and 3. if it comes from the AI response
    .replace(/([.?!])\s*(?=[A-Z])/g, '$1|')
    .split('|')
    .map(function (sentence, index) {
      return {
        text: sentence,
        id: index + 1,
      };
    })
    .slice(0, 3);

  return (
    <View style={pdfStyles.performanceMetricsContainer}>
      <View style={pdfStyles.performanceMetricsHeader}>
        <Image
          src={iconSrc}
          style={{
            width: iconWidth ?? 25,
            height: 25,
          }}
        />
        <Text style={pdfStyles.performanceMetricsLabel}>{label}</Text>
      </View>
      {!showAsList && (
        <Text
          style={{
            fontSize: 14,
            color: '#737373',
          }}
        >
          {truncateText(text, 260)}
        </Text>
      )}

      {showAsList &&
        sentences.map((sentence) => (
          <Text
            style={{
              fontSize: 14,
              color: '#737373',
            }}
            key={sentence.id}
          >
            {`${sentence.id}) ${truncateText(sentence.text, 180)}`}
          </Text>
        ))}
    </View>
  );
};

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

export default MonthlyReportPDFDocument;
