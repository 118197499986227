import { StyleSheet } from '@react-pdf/renderer';

const pdfStyles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#F3F5F6',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 10,
  },
  date: {
    fontSize: 12,
    fontWeight: 600,
  },
  page: {
    flexDirection: 'column',
    fontFamily: 'figtree',
    color: '#4C5257',
  },
  notification: {
    alignSelf: 'center',
    fontSize: 12,
    color: '#868E96',
    marginBottom: 10,
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading1: {
    margin: 5,
    fontWeight: 'bold',
  },
  heading2: {
    margin: 5,
    fontWeight: 'bold',
  },
  heading3: {
    margin: 5,
    fontSize: 14,
    fontWeight: 'bold',
  },
  heading4: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'semibold',
  },
  heading5: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'semibold',
  },
  heading6: {
    margin: 5,
    fontSize: 11,
    fontWeight: 'semibold',
  },
  paragraph: {
    paddingLeft: 6,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: 'justify',
    fontWeight: 400,
  },
  listBullet: {
    width: 10,
  },
  strong: {
    fontWeight: 'semibold',
  },
  italic: {
    fontStyle: 'italic',
  },
  blockquote: {
    paddingHorizontal: 12,
    borderLeft: '3px solid #868E96',
    margin: 10,
    fontStyle: 'italic',
  },
  code: {
    margin: 10,
    fontFamily: 'DroidSans',
    fontStyle: 'normal',
    fontSize: 10,
    backgroundColor: '#f5f5f5',
    padding: 10,
    borderRadius: 5,
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
  performanceMetricsContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  performanceMetricsHeader: {
    gap: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  performanceMetricsLabel: {
    fontSize: 20,
    color: '#000000',
    textDecoration: 'underline',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#101010',
  },
});

export default pdfStyles;
