import { joinUrls } from '../../utils/join-url';

const paypalSubscriptionCharge = async ({
  name,
  email,
  plan_id,
  quantity,
  multiplier,
}: {
  email: string;
  name: string;
  plan_id: string;
  quantity?: number;
  multiplier?: boolean;
}) => {
  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/charge'),
    {
      method: 'POST',
      body: JSON.stringify({
        pay_id: 'paypal',
        email: email,
        name: name,
        planId: plan_id,
        membership: true,
        quantity: quantity ?? 1,
        multiplier: multiplier ? 2 : 1,
        type: 'sub',
      }),
    }
  );

  const data = await response.json();

  return data;
};

export { paypalSubscriptionCharge };
