import { joinUrls } from '../../utils/join-url';

type BuySupportPlanRequest = {
  name: string;
  email: string;
  subscription_id: string;
  pay_id: 'stripe' | 'paypal' | 'paypal_capture' | 'stripe_capture';
  order_id: string | null;
  payment_intent: string | null;
};

const buySupportPlan = async ({
  name,
  email,
  pay_id,
  order_id,
  payment_intent,
  subscription_id,
}: BuySupportPlanRequest) => {
  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/buy-support-plan'),
    {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
        pay_id,
        order_id,
        payment_intent,
        subscription_id,
      }),
    }
  );

  const data = await response.json();

  return data;
};

export { buySupportPlan };
