import { create } from 'zustand';
import { getPrices, PlanType } from '../../consts';

type SelectedPlan = {
  selected_plan: PlanType;
  product_price: number;
  original_price: number;
  paypal_plan_id: string;
  stripe_plan_id: string;
  msrp: number;
  title: string;
  quantity?: number;
  substitle?: string;
  multiplier?: boolean;

  setSelectedPlan?: (selectedPlan: SelectedPlan) => void;
};

const useSelectedPlanStore = create<SelectedPlan>((set) => ({
  selected_plan: 'basic',
  product_price: getPrices().basic.product_price,
  original_price: getPrices().basic.original_price,
  paypal_plan_id: getPrices().basic.paypal,
  stripe_plan_id: getPrices().basic.stripe,
  msrp: 20,
  title: getPrices().basic.title,
  quantity: 1,
  multiplier: false,

  setSelectedPlan: (selectedPlan: SelectedPlan) => {
    set(() => ({ ...selectedPlan }));
  },
}));

export { useSelectedPlanStore };
