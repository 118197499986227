import { create } from 'zustand';
import { StripePromoCode } from './apply-stripe-coupon';

type CouponStore = {
  stripePromoCode: StripePromoCode | null;
  setStripePromoCode: (stripePromoCode: StripePromoCode) => void;
  removeStripePromoCode: () => void;
};

const useCouponStore = create<CouponStore>((set) => ({
  stripePromoCode: null,
  setStripePromoCode: (stripePromoCode) => {
    set(() => ({ stripePromoCode: { ...stripePromoCode } }));
  },
  removeStripePromoCode: () => {
    set(() => ({ stripePromoCode: null }));
  },
}));

export { useCouponStore };
