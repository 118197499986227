import { useMemo } from 'react';
import PlanStatus from './PlanStatus';
import Domain from './Domain';
import { getPrices } from '../../../consts';
import { Select } from '@headlessui/react';

const prices = getPrices() as unknown as any;

type StripeSubscription = {
  status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'paused';
  start_date: number;
  cancel_at: number;
  plan: {
    id: string;
    nickname: string;
    amount: number;
    currency: string;
    interval: string;
  };
  trial_start: number;
  trial_end: number;
  canceled_at: number;
  current_period_start: number;
  current_period_end: number;
  id: string;
};

export default function StripePlan({
  subscription,
  meta,
  onPlanUpgrade,
  onPlanCancel,
  expandAccordionForPlanHandler,
  domain,
}: Readonly<{
  subscription: StripeSubscription;
  meta: {
    license_key: string;
    allowed_domains: string[];
    support_order_id?: string;
  };
  onPlanUpgrade: (
    subscription: StripeSubscription,
    meta: { license_key: string },
    plan: string
  ) => void;
  onPlanCancel: (
    subscription: StripeSubscription,
    meta: { license_key: string }
  ) => void;
  expandAccordionForPlanHandler: (item_id: string) => void;
  domain: string;
}>) {
  const isActive =
    subscription.status.toLowerCase() === 'active' ||
    subscription.status.toLowerCase() === 'trialing';

  const upgradablePlans = useMemo(() => {
    const upgradables = [];
    let cur_price = null;
    for (cur_price in prices) {
      if (subscription.plan.id === prices[cur_price].stripe) break;
    }
    for (let price in prices) {
      if (prices[price].product_price > prices[cur_price as any].product_price)
        upgradables.push({ name: price, ...prices[price] });
    }
    return upgradables;
  }, [subscription]);

  return (
    <>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {subscription.plan.nickname}{' '}
        {meta.support_order_id && `(Premium Support)`}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {(subscription.plan.amount / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: subscription.plan.currency,
        })}
        &nbsp; / {subscription.plan.interval}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <PlanStatus status={subscription.status as 'active' | 'trialing'} />
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {subscription.start_date
          ? new Date(subscription.start_date * 1000).toLocaleDateString()
          : 'NA'}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {subscription.status === 'active' &&
          subscription.cancel_at > 0 &&
          new Date(subscription.cancel_at * 1000).toLocaleDateString()}

        {subscription.status === 'active' &&
          new Date(subscription.current_period_end * 1000).toLocaleDateString()}

        {subscription.status === 'canceled' &&
          new Date(subscription.current_period_end * 1000).toLocaleDateString()}

        {subscription.status === 'trialing' &&
          new Date(subscription.trial_end * 1000).toLocaleDateString()}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        Credit Card
      </td>
      <td className='whitespace-nowrap py-3 text-sm font-medium text-gray-900 flex items-center'>
        <Domain
          domain={domain}
          item_id={subscription.id}
          isActive={isActive}
          expandAccordionForPlanHandler={expandAccordionForPlanHandler}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <Select
          disabled={!isActive}
          onChange={(e) => onPlanUpgrade(subscription, meta, e.target.value)}
          className='px-1 py-2 g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg capitalize focus:ring-blue-500 focus:border-blue-500 block w-full'
        >
          <option value=''>Select Plan</option>
          {upgradablePlans.map((upgradable) => (
            <option
              value={upgradable.name}
              key={upgradable.name}
              className='py-2 px-1'
            >
              {upgradable.name}
            </option>
          ))}
        </Select>
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <button
          onClick={() => onPlanCancel(subscription, meta)}
          disabled={!isActive}
          className={`${!isActive ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          Cancel
        </button>
      </td>
    </>
  );
}
