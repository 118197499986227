import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, ChangeEvent, useState } from 'react';
import { getMonthlyReportList } from './monthly-report-list.api';
import MonthlyAnalytics from './MonthlyAnalytics';
import { pdf } from '@react-pdf/renderer';
import MonthlyReportPDFDocument from './MonthlyReportPDF';
import useMonthlyReportStore from './monthly-report.store';
import { ImSpinner2 } from 'react-icons/im';

export const MonthlyReport = () => {
  const { data: monthlyReportList, isLoading: isMonthlyReportListLoading } =
    useQuery({
      queryKey: ['monthlyReportList'],
      queryFn: getMonthlyReportList,
    });

  const monthlyReport = useMonthlyReportStore((state) => state.report);

  const [selectedDomain, setSelectedDomain] = useState<string | undefined>();
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const [currentReportState, setCurrentReportState] = useState<{
    domain: string;
    license_key: string;
    report_date: string;
  } | null>(null);

  useEffect(() => {
    if (!monthlyReportList) return;

    if (monthlyReportList?.length > 0) {
      const initialDomain = monthlyReportList[0].domain;
      setSelectedDomain(initialDomain);

      const initialReport = monthlyReportList.find(
        (report) => report.domain === initialDomain
      );

      if (initialReport && initialReport.report_dates.length > 0) {
        const latestDate = initialReport.report_dates[0];
        setSelectedDate(latestDate);

        setCurrentReportState({
          domain: initialDomain,
          license_key: initialReport?.license_key,
          report_date: latestDate,
        });
      }
    }
  }, [monthlyReportList]);

  const handleDomainChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDomain(e.target.value);
    const selectedReport = monthlyReportList?.find(
      (report) => report.domain === e.target.value
    );
    if (selectedReport && selectedReport.report_dates.length > 0) {
      const latestDate = selectedReport.report_dates[0];
      setSelectedDate(latestDate);
    }
  };

  const handleDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.target.value);
  };

  const handleFindClick = () => {
    setCurrentReportState({
      domain: selectedDomain!,
      license_key: monthlyReportList?.find(
        (report) => report.domain === selectedDomain
      )?.license_key!,
      report_date: selectedDate!,
    });
  };

  const handlePrintAnalytics = async () => {
    const blob = await pdf(
      <MonthlyReportPDFDocument
        aiReport={monthlyReport?.aiReport!}
        reportDate={monthlyReport?.report_date!}
        domain={currentReportState?.domain!}
      />
    ).toBlob();

    const pdfFileName = `${currentReportState?.domain}-${currentReportState?.report_date}.pdf`;

    const pdfFile = new File([blob], pdfFileName, {
      type: 'application/pdf',
    });

    const url = URL.createObjectURL(pdfFile);
    const a = document.createElement('a');
    a.href = url;
    a.download = pdfFileName;
    a.click();
  };

  const printAnalyticsMutation = useMutation({
    mutationFn: handlePrintAnalytics,
    mutationKey: ['printAnalytics'],
  });

  const domainOptions = monthlyReportList?.map((report) => (
    <option key={report.domain} value={report.domain}>
      {report.domain}
    </option>
  ));

  const selectedReport = monthlyReportList?.find(
    (report) => report.domain === selectedDomain
  );

  const dateOptions = selectedReport?.report_dates.map((date) => (
    <option key={date} value={date}>
      {new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
      })}
    </option>
  ));

  if (isMonthlyReportListLoading) {
    return <div>Loading...</div>;
  }

  if (monthlyReportList?.length === 0) {
    return <div>No monthly reports generated yet</div>;
  }

  return (
    <>
      <div className='flex justify-end space-x-4 items-end'>
        <div className='flex flex-col'>
          <label
            className='text-sm font-medium text-gray-700 mb-1'
            htmlFor='domain-select'
          >
            Select Domain
          </label>
          <div className='relative'>
            <select
              id='domain-select'
              className='appearance-none w-48 bg-white border border-gray-300 text-gray-700 py-2 pl-3 pr-8 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
              value={selectedDomain}
              onChange={handleDomainChange}
            >
              {domainOptions}
            </select>
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
              <svg
                className='h-4 w-4 fill-current'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <path d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' />
              </svg>
            </div>
          </div>
        </div>

        <div className='flex flex-col'>
          <label
            className='text-sm font-medium text-gray-700 mb-1'
            htmlFor='date-select'
          >
            Select Date
          </label>
          <div className='relative'>
            <select
              id='date-select'
              className='appearance-none w-48 bg-white border border-gray-300 text-gray-700 py-2 pl-3 pr-8 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500'
              value={selectedDate}
              onChange={handleDateChange}
              disabled={!selectedDomain}
            >
              {dateOptions}
            </select>
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
              <svg
                className='h-4 w-4 fill-current'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
              >
                <path d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z' />
              </svg>
            </div>
          </div>
        </div>

        <button
          className='bg-blue-600 text-white font-semibold rounded-md p-2 h-10 hover:bg-blue-700 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all'
          onClick={handleFindClick}
          disabled={!selectedDate}
        >
          Find
        </button>

        <button
          className='bg-blue-600 text-white font-semibold rounded-md p-2 h-10 hover:bg-blue-700 disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all flex items-center gap-x-2'
          onClick={() => printAnalyticsMutation.mutate()}
          disabled={
            !selectedDate ||
            monthlyReport === null ||
            printAnalyticsMutation.isPending
          }
        >
          {printAnalyticsMutation.isPending && (
            <ImSpinner2 className='animate-spin text-gray-400' size={16} />
          )}
          <span>PDF</span>
        </button>
      </div>
      <div className='mt-4'>
        <MonthlyAnalytics reportState={currentReportState} />
      </div>
    </>
  );
};
