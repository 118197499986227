import { fetchAuthSession } from 'aws-amplify/auth';
import { joinUrls } from '../../../utils/join-url';

const getMonthlyReport = async ({
  license_key,
  report_date,
}: {
  license_key: string;
  report_date: string;
}): Promise<TrafficReport> => {
  const token = (await fetchAuthSession()).tokens?.idToken?.toString();

  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/get-monthly-report'),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify({
        license_key,
        report_date,
      }),
    }
  );

  const data = response.json();

  if (!response.ok) {
    throw new Error((data as any).message);
  }

  return data;
};

export { getMonthlyReport };

type TrafficSource = {
  count: number;
  percentage: number;
};

type UTMSource = TrafficSource & {
  utm_source: string;
};

type BrowserType = TrafficSource & {
  browser: string;
};

type TrafficType = TrafficSource & {
  traffic_source: string;
};

type UTMCampaign = TrafficSource & {
  utm_campaign: string;
};

type DeviceType = TrafficSource & {
  device_type: string;
};

type OrganicSourceType = TrafficSource & {
  organic_source_str: string;
};

export type AIReport = {
  executive_summary: string;
  traffic_source: {
    thriving: string | string[];
    challenges: string | string[];
    strategies: string | string[];
  };
  paid: {
    thriving: string | string[];
    challenges: string | string[];
    strategies: string | string[];
  };
  organic: {
    thriving: string | string[];
    challenges: string | string[];
    strategies: string | string[];
  };
  device: {
    observations: string | string[];
    strategies: string | string[];
  };
  browser: {
    observations: string | string[];
    strategies: string | string[];
  };
};

export type TrafficReport = {
  license_key: string;
  trafficByUTMSource: UTMSource[];
  trafficByBrowserType: BrowserType[];
  report_date: string;
  trafficByTrafficSource: TrafficType[];
  trafficByCampaign: UTMCampaign[];
  trafficByDeviceType: DeviceType[];
  trafficByOrganicSourceType: OrganicSourceType[];
  aiReport: AIReport;
};
