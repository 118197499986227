import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ChangeEmailModal from './ChangeEmailModal';
import { fetchAuthSession, updateUserAttributes } from 'aws-amplify/auth';
import { useAuthStore } from '../../../features/auth/AuthStore';
import { FaUserCircle, FaSpinner } from 'react-icons/fa';
import { MdEmail, MdLocationPin, MdReceiptLong } from 'react-icons/md';
import { GoOrganization } from 'react-icons/go';

import './MyAccounts.css';

type FormValues = {
  name: string;
  email: string;
  companyName: string;
  address: string;
  vat: string;
};

export default function MyAccounts() {
  const [loading, setLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');

  const userAttributes = useAuthStore((state) => state.userAttributes);
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required'),
    email: Yup.string()
      .email('Please enter a valid email')
      .required('This field is required'),
    companyName: Yup.string().required('This field is required'),
    address: Yup.string().required('This field is required'),
    vat: Yup.string().required('This field is required'),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
      address: '',
      vat: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const { name, companyName, address, vat } = values;
      try {
        const _userAttributes = {
          'custom:name': name,
          'custom:company_name': companyName,
          'custom:address': address,
          'custom:vat': vat,
        };

        await updateUserAttributes({ userAttributes: _userAttributes });
        toast.success('Billing info updated successfully', {
          position: 'top-right',
        });
        await fetchAuthSession({ forceRefresh: true });

        setUserAttributes({ email: userAttributes.email, ..._userAttributes });
      } catch (error: any) {
        setErrMsg(error?.response?.data?.message ?? 'Something went wrong');
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('email', userAttributes.email);
  }, [userAttributes.email]);

  useEffect(() => {
    formik.setFieldValue('name', userAttributes['custom:name']);
    formik.setFieldValue('companyName', userAttributes['custom:company_name']);
    formik.setFieldValue('address', userAttributes['custom:address']);
    formik.setFieldValue('vat', userAttributes['custom:vat']);
  }, [userAttributes]);

  const getClassNames = (field: string, touched: boolean, errors: string) =>
    touched && errors
      ? 'rounded-none rounded-r-lg bg-gray-50 border border-red-500 text-red-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5 placeholder-red-700'
      : 'rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full p-2.5';

  const [changeEmailModal, setChangeEmailModal] = useState<boolean>(false);

  return (
    <div className='w-full sm:w-[70%] md:w-[80%] lg:w-[50%] m-auto'>
      <ChangeEmailModal
        changeEmailModal={changeEmailModal}
        setChangeEmailModal={setChangeEmailModal}
      />
      <p className='text-center text-[24px] font-medium my-10'>
        Welcome to My Account 👋
      </p>
      <div className='px-10'>
        {errMsg && (
          <div className='w-full py-3 px-5 bg-red-500 text-white text-center mb-2'>
            {errMsg}
          </div>
        )}
        <form onSubmit={formik.handleSubmit} method='POST'>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <FaUserCircle className='w-5 h-5 text-gray-500' />
            </span>
            <input
              type='text'
              className={getClassNames(
                'name',
                formik.touched.name!,
                formik.errors.name!
              )}
              placeholder={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : 'Name'
              }
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex mb-2 relative'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <MdEmail className='w-5 h-5 text-gray-500' />
            </span>
            <input
              type='email'
              name='email'
              disabled
              className={getClassNames(
                'email',
                formik.touched.email!,
                formik.errors.email!
              )}
              placeholder={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : 'Email address'
              }
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <div className='absolute -right-7 top-1/2 -translate-y-1/2 flex items-center justify-center'>
              <button
                type='button'
                onClick={() => setChangeEmailModal(true)}
              ></button>
            </div>
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <GoOrganization className='w-5 h-5 text-gray-600' />
            </span>
            <input
              type='text'
              className={getClassNames(
                'companyName',
                formik.touched.companyName!,
                formik.errors.companyName!
              )}
              placeholder={
                formik.touched.companyName && formik.errors.companyName
                  ? formik.errors.companyName
                  : 'Company Name'
              }
              name='companyName'
              value={formik.values.companyName}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <MdLocationPin className='w-5 h-5 text-gray-500' />
            </span>
            <input
              type='text'
              className={getClassNames(
                'address',
                formik.touched.address!,
                formik.errors.address!
              )}
              placeholder={
                formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : 'Address'
              }
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
          <div className='flex mb-2'>
            <span className='inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md'>
              <MdReceiptLong className='w-5 h-5 text-gray-600' />
            </span>
            <input
              type='text'
              className={getClassNames(
                'vat',
                formik.touched.vat!,
                formik.errors.vat!
              )}
              placeholder={
                formik.touched.vat && formik.errors.vat
                  ? formik.errors.vat
                  : 'VAT'
              }
              name='vat'
              value={formik.values.vat}
              onChange={formik.handleChange}
            />
          </div>
          <div className='form-group'>
            <button
              disabled={loading}
              type='submit'
              className={`btn btn-default btn-block btn-sm bg-[#0084ff] disabled:bg-gray-400 w-full h-12 flex justify-center items-center ${
                formik.isValid ? '' : 'bg-gray-400 cursor-not-allowed'
              }`}
            >
              {loading && (
                <FaSpinner className='w-5 h-5 text-white icon_pulse' />
              )}
              {!loading && <span>Save Account</span>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
