import { useMemo } from 'react';
import PlanStatus from './PlanStatus';
import Domain from './Domain';
import { getPrices } from '../../../consts';
import { Select } from '@headlessui/react';

const prices = getPrices();

export default function PaypalPlan({
  subscription,
  meta,
  onPlanUpgrade,
  plan,
  onPlanCancel,
  domain,
  expandAccordionForPlanHandler,
}: Readonly<{
  subscription: PayPalSubscription;
  meta: {
    license_key: string;
    allowed_domains: string[];
    support_order_id?: string;
  };
  onPlanUpgrade: any;
  plan: PayPalPlan;
  onPlanCancel: any;
  domain: string;
  expandAccordionForPlanHandler: (item_id: string) => void;
}>) {
  const isActive =
    subscription.status.toLowerCase() === 'active' ||
    subscription.status.toLowerCase() === 'trialing';

  const upgradablePlans = useMemo(() => {
    const upgradables = [];
    let cur_price: any = null;

    for (cur_price in prices) {
      if (subscription.plan_id === (prices as any)[cur_price].paypal) break;
    }

    for (let price in prices) {
      if (
        (prices as any)[price].product_price >
        (prices as any)[cur_price].product_price
      )
        upgradables.push({ name: price, ...(prices as any)[price] });
    }

    return upgradables;
  }, [subscription]);

  return (
    <>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {plan.name} {meta.support_order_id && `(Premium Support)`}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {parseInt(
          plan.billing_cycles[1].pricing_scheme.fixed_price.value
        ).toLocaleString('en-US', {
          style: 'currency',
          currency:
            plan.billing_cycles[1].pricing_scheme.fixed_price.currency_code,
        })}{' '}
        /{' '}
        {plan.billing_cycles[1]?.frequency.interval_unit === 'DAY' &&
          plan.billing_cycles[1].frequency.interval_count}{' '}
        {plan.billing_cycles[1].frequency.interval_unit.toLocaleLowerCase()}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <PlanStatus status={getPlanStatus(subscription)} />
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {subscription.billing_info.last_payment
          ? new Date(
              subscription.billing_info.last_payment.time
            ).toLocaleDateString()
          : new Date(subscription.start_time).toLocaleDateString()}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {subscription.billing_info.next_billing_time
          ? new Date(
              subscription.billing_info.next_billing_time
            ).toLocaleDateString()
          : 'NA'}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        Paypal
      </td>
      <td className='whitespace-nowrap py-3 text-sm font-medium text-gray-900 flex items-center'>
        <Domain
          domain={domain}
          item_id={subscription.id}
          isActive={isActive}
          expandAccordionForPlanHandler={expandAccordionForPlanHandler}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <Select
          disabled={!isActive}
          onChange={(e) => onPlanUpgrade(subscription, meta, e.target.value)}
          className='px-1 py-2 g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg capitalize focus:ring-blue-500 focus:border-blue-500 block w-full'
        >
          <option value=''>Select Plan</option>
          {upgradablePlans.map((upgradable) => (
            <option
              value={upgradable.name}
              key={upgradable.name}
              className='py-2 px-1'
            >
              {upgradable.name}
            </option>
          ))}
        </Select>
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <button
          onClick={() => onPlanCancel(subscription, meta)}
          disabled={!isActive}
          className={`${!isActive ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          Cancel
        </button>
      </td>
    </>
  );
}

const getPlanStatus = (subscription: PayPalSubscription) => {
  if (subscription.status === 'CANCELLED') {
    return 'canceled';
  }

  if (
    subscription.status === 'ACTIVE' &&
    !subscription.billing_info.last_payment
  ) {
    return 'trialing';
  }

  return 'active';
};

type PayPalPlan = {
  id: string;
  product_id: string;
  name: string;
  status: 'ACTIVE' | 'INACTIVE';
  usage_type: 'LICENSED' | 'UNLICENSED';
  billing_cycles: {
    pricing_scheme: {
      version: number;
      fixed_price: {
        currency_code: string;
        value: string;
      };
      create_time: string;
      update_time: string;
    };
    frequency: {
      interval_unit: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
      interval_count: number;
    };
    tenure_type: 'TRIAL' | 'REGULAR';
    sequence: number;
    total_cycles: number;
  }[];
  payment_preferences: {
    service_type: 'PREPAID' | 'POSTPAID';
    auto_bill_outstanding: boolean;
    setup_fee: {
      currency_code: string;
      value: string;
    };
    setup_fee_failure_action: 'CONTINUE' | 'CANCEL';
    payment_failure_threshold: number;
  };
  quantity_supported: boolean;
  create_time: string;
  update_time: string;
  links: {
    href: string;
    rel: 'self' | 'edit' | 'deactivate';
    method: 'GET' | 'PATCH' | 'POST';
    encType: 'application/json';
  }[];
};

type PayPalSubscription = {
  status: string;
  status_update_time: string;
  id: string;
  plan_id: string;
  start_time: string;
  quantity: string;
  shipping_amount: {
    currency_code: string;
    value: string;
  };
  subscriber: {
    email_address: string;
    payer_id: string;
    name: {
      given_name: string;
      surname: string;
    };
    shipping_address: {
      address: {
        address_line_1: string;
        admin_area_2: string;
        admin_area_1: string;
        postal_code: string;
        country_code: string;
      };
    };
  };
  billing_info: {
    outstanding_balance: {
      currency_code: string;
      value: string;
    };
    cycle_executions: {
      tenure_type: string;
      sequence: number;
      cycles_completed: number;
      cycles_remaining: number;
      current_pricing_scheme_version: number;
      total_cycles: number;
    }[];
    next_billing_time: string;
    failed_payments_count: number;
    last_payment?: {
      amount: {
        currency_code: string;
        value: string;
      };
      time: string;
    };
  };
  create_time: string;
  update_time: string;
  custom_id: string;
  plan_overridden: boolean;
  links: {
    href: string;
    rel: string;
    method: string;
  }[];
};
