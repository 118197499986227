import { useMutation } from '@tanstack/react-query';
import { getMonthlyReport } from './monthly-report.api';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect } from 'react';
import useMonthlyReportStore from './monthly-report.store';

import './MonthlyAnalytics.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const MonthlyAnalytics = ({
  reportState,
}: {
  reportState: {
    domain: string;
    license_key: string;
    report_date: string;
  } | null;
}) => {
  const setReport = useMonthlyReportStore((state) => state.setReport);

  const monthlyReportMutation = useMutation({
    mutationKey: ['monthlyReport'],
    mutationFn: ({
      license_key,
      report_date,
    }: {
      license_key: string;
      report_date: string;
    }) => {
      return getMonthlyReport({ license_key, report_date });
    },
  });

  useEffect(() => {
    if (reportState) {
      monthlyReportMutation.mutate({
        license_key: reportState.license_key,
        report_date: reportState.report_date,
      });
    }
  }, [reportState]);

  useEffect(() => {
    if (monthlyReportMutation.isPending) return;
    if (!monthlyReportMutation.data) return;

    setReport(monthlyReportMutation.data);
  }, [monthlyReportMutation.data]);

  if (
    monthlyReportMutation.isPending ||
    !reportState ||
    !monthlyReportMutation.data
  ) {
    return <div>Loading...</div>;
  }

  if (monthlyReportMutation.isError) {
    return <div>Error loading report</div>;
  }

  const reportData = monthlyReportMutation.data;

  const createChartData = (
    labels: string[],
    data: number[],
    title: string
  ) => ({
    labels,
    datasets: [
      {
        label: title,
        data,
        borderWidth: 1,
      },
    ],
  });

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.label}: ${context.raw} (${context.raw}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: any) => `${value}`,
        },
      },
    },
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
    borderColor: 'rgb(153, 102, 255)',
  };

  return (
    <>
      <section className='mb-4 p-4'>
        <h1 className='text-2xl font-semibold mb-2'>Executive Summary</h1>
        <hr />
        <p>{reportData.aiReport.executive_summary}</p>
      </section>
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 p-4'>
        <div className='w-full col-span-2 sm:col-span-2 grid grid-cols-1 sm:grid-cols-2 bg-white p-4 rounded bar-chart-shadow mx-1'>
          <div>
            <h2 className='text-xl text-center mb-4'>Traffic by UTM Source</h2>
            <Bar
              data={createChartData(
                reportData.trafficByUTMSource.map((item) => item.utm_source),
                reportData.trafficByUTMSource.map((item) => item.count),
                'UTM Source'
              )}
              options={{
                ...barOptions,
                // https://stackoverflow.com/questions/75210938/how-to-use-chart-js-or-recharts-in-react-pdf-renderer
                animation: {
                  onComplete: (event) => {
                    const image = event.chart.toBase64Image();
                    localStorage.setItem('trafficByUtmSourceImage', image);
                  },
                },
              }}
            />
          </div>
          <div className='w-full'>
            <h2 className='text-xl text-center mb-4'>Traffic by Campaign</h2>
            <Bar
              data={createChartData(
                reportData.trafficByCampaign.map((item) => item.utm_campaign),
                reportData.trafficByCampaign.map((item) => item.count),
                'Campaign'
              )}
              options={{
                ...barOptions,
                animation: {
                  onComplete: (event) => {
                    const image = event.chart.toBase64Image();
                    localStorage.setItem('trafficByUtmCampaignImage', image);
                  },
                },
              }}
            />
          </div>

          <section className='w-full col-span-2 sm:col-span-2'>
            <h3 className='text-lg font-semibold mt-4'>Observations</h3>
            <p>{reportData.aiReport.paid.challenges}</p>

            <h3 className='text-lg font-semibold mt-4'>Strategies</h3>
            <p>{reportData.aiReport.paid.strategies}</p>

            <h3 className='text-lg font-semibold mt-4'>Thriving</h3>
            <p>{reportData.aiReport.paid.thriving}</p>
          </section>
        </div>

        <div className='w-full bg-white p-4 rounded bar-chart-shadow mx-1'>
          <h2 className='text-xl text-center mb-4'>Traffic by Device Type</h2>
          <Bar
            data={createChartData(
              reportData.trafficByDeviceType.map((item) => item.device_type),
              reportData.trafficByDeviceType.map((item) => item.count),
              'Device Type'
            )}
            options={{
              ...barOptions,
              animation: {
                onComplete: (event) => {
                  const image = event.chart.toBase64Image();
                  localStorage.setItem('trafficByDeviceTypeImage', image);
                },
              },
            }}
          />

          <section>
            <h3 className='text-lg font-semibold mt-4'>Observations</h3>
            <p>{reportData.aiReport.device.observations}</p>

            <h3 className='text-lg font-semibold mt-4'>Strategies</h3>
            <p>{reportData.aiReport.device.strategies}</p>
          </section>
        </div>

        <div className='w-full bg-white p-4 rounded bar-chart-shadow mx-1'>
          <h2 className='text-xl text-center mb-4'>Traffic by Browser Type</h2>
          <Bar
            data={createChartData(
              reportData.trafficByBrowserType.map((item) => item.browser),
              reportData.trafficByBrowserType.map((item) => item.count),
              'Browser Type'
            )}
            options={{
              ...barOptions,
              animation: {
                onComplete: (event) => {
                  const image = event.chart.toBase64Image();
                  localStorage.setItem('trafficByBrowserTypeImage', image);
                },
              },
            }}
          />

          <section>
            <h3 className='text-lg font-semibold mt-4'>Observations</h3>
            <p>{reportData.aiReport.browser.observations}</p>

            <h3 className='text-lg font-semibold mt-4'>Strategies</h3>
            <p>{reportData.aiReport.browser.strategies}</p>
          </section>
        </div>

        <div className='w-full bg-white p-4 rounded bar-chart-shadow mx-1'>
          <h2 className='text-xl text-center mb-4'>
            Traffic by Traffic Source
          </h2>
          <Bar
            data={createChartData(
              reportData.trafficByTrafficSource.map(
                (item) => item.traffic_source
              ),
              reportData.trafficByTrafficSource.map((item) => item.count),
              'Traffic Source'
            )}
            options={{
              ...barOptions,
              animation: {
                onComplete: (event) => {
                  const image = event.chart.toBase64Image();
                  localStorage.setItem('trafficByTrafficSourceImage', image);
                },
              },
            }}
          />

          <section>
            <h3 className='text-lg font-semibold mt-4'>Observations</h3>
            <p>{reportData.aiReport.traffic_source.challenges}</p>

            <h3 className='text-lg font-semibold mt-4'>Strategies</h3>
            <p>{reportData.aiReport.traffic_source.strategies}</p>

            <h3 className='text-lg font-semibold mt-4'>Thriving</h3>
            <p>{reportData.aiReport.traffic_source.thriving}</p>
          </section>
        </div>

        <div className='w-full bg-white p-4 rounded bar-chart-shadow mx-1'>
          <h2 className='text-xl text-center mb-4'>
            Traffic by Organic Source Type
          </h2>
          <Bar
            data={createChartData(
              reportData.trafficByOrganicSourceType.map(
                (item) => item.organic_source_str
              ),
              reportData.trafficByOrganicSourceType.map((item) => item.count),
              'Organic Source'
            )}
            options={{
              ...barOptions,
              animation: {
                onComplete: (event) => {
                  const image = event.chart.toBase64Image();
                  localStorage.setItem('trafficByOrganicSourceImage', image);
                },
              },
            }}
          />

          <section>
            <h3 className='text-lg font-semibold mt-4'>Observations</h3>
            <p>{reportData.aiReport.organic.challenges}</p>

            <h3 className='text-lg font-semibold mt-4'>Strategies</h3>
            <p>{reportData.aiReport.organic.strategies}</p>

            <h3 className='text-lg font-semibold mt-4'>Thriving</h3>
            <p>{reportData.aiReport.organic.thriving}</p>
          </section>
        </div>
      </div>
    </>
  );
};

export default MonthlyAnalytics;
