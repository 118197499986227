import { FormEvent, useMemo, useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckout/StripeCheckoutForm';
import { AuthUser, signUp } from 'aws-amplify/auth';
import SimpleReactValidator from 'simple-react-validator';
import { loadStripe } from '@stripe/stripe-js';
import { dateReformat } from '../Common';
import { Transition } from '@headlessui/react';
import { ImSpinner2 } from 'react-icons/im';

import './PaymentForm.css';
import { useAuthStore } from '../../features/auth/AuthStore';
import { useSelectedPlanStore } from '../../features/auth/SelectedPlanStore';
import { getAccountInfo } from '../AccountInfos/Subscriptions/account-info.api';
import { MINUTE } from '../../consts/time';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paypalSubscriptionCharge } from './paypal-subscription-charge.api';
import { useCouponStore } from './StripeCheckout/coupon.store';

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

declare global {
  interface Window {
    Cookies: any;
    gtag: any;
  }
}

const PaymentForm = () => {
  const date = new Date();
  const trial_end = date.setDate(date.getDate() + 7);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCreditCardFormVisible, setIsCreditCardFormVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [ccProcessing, setCcProcessing] = useState(false);
  const { Cookies, gtag } = window;

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const selected_plan = useSelectedPlanStore((state) => state.selected_plan);
  const title = useSelectedPlanStore((state) => state.title);
  const quantity = useSelectedPlanStore((state) => state.quantity);
  const product_price = useSelectedPlanStore((state) => state.product_price);
  const paypal_plan_id = useSelectedPlanStore((state) => state.paypal_plan_id);
  const multiplier = useSelectedPlanStore((state) => state.multiplier);

  const setAuthUser = useAuthStore((state) => state.setAuthUser);
  const setPendingUser = useAuthStore((state) => state.setPendingUser);
  const pendingUser = useAuthStore((state) => state.pendingUser);
  const stripePromoCode = useCouponStore((state) => state.stripePromoCode);

  const { data: accountInfo } = useQuery({
    queryKey: ['accountInfo'],
    queryFn: getAccountInfo,
    staleTime: 10 * MINUTE,
  });

  const haveAlreadyBought = accountInfo?.subscriptions?.length > 0;

  const validationRef = useRef(
    new SimpleReactValidator({
      element: (message: string, className: string) => (
        <div className={className}>{message}</div>
      ),
      messages: {
        in: 'Passwords need to match!',
      },
    })
  );

  const onFirstStepSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!validationRef.current.allValid()) {
      validationRef.current.showMessages();
      setIsFormInvalid(true);
      return;
    }

    setCcProcessing(true);
    setIsCreditCardFormVisible(false);
    try {
      const user = await signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            given_name: firstName,
            middle_name: '',
            'custom:utm_campaign': Cookies.get('utm_campaign') ?? 'NA',
            'custom:utm_medium': Cookies.get('utm_medium') ?? 'NA',
            'custom:utm_term': Cookies.get('utm_term') ?? 'NA',
            'custom:utm_content': Cookies.get('utm_content') ?? 'NA',
            'custom:utm_source': Cookies.get('utm_source') ?? 'NA',
            'custom:ip': Cookies.get('handl_js_ip') ?? 'NA',
            'custom:user_agent': Cookies.get('user_agent') ?? 'NA',
            'custom:_fbc': Cookies.get('_fbc') ?? 'NA',
            'custom:_fbp': Cookies.get('_fbp') ?? 'NA',
            'custom:gclid': Cookies.get('gclid') ?? 'NA',
            'custom:fbclid': Cookies.get('fbclid') ?? 'NA',
          },
        },
      });

      console.log(user);

      setAuthUser(user as unknown as AuthUser);
      gtag('event', 'view_item', {
        items: [
          {
            id: selected_plan,
            name: 'UTMSimple Tracker',
            brand: 'UTMSimple',
            variant: title,
            list_position: 2,
            quantity: quantity,
            price: `${product_price}.00`,
          },
        ],
        email: email,
      });

      gtag('event', 'generate_lead', {
        email: email,
      });
      // Event snippet for Subscribed For Free conversion page
      gtag('event', 'conversion', {
        send_to: 'AW-604754791/4njECKyF7u4CEOemr6AC',
        value: 1.0,
        currency: 'USD',
        transaction_id: '',
      });

      // console.log("triggered...")
      setErrorMsg('');
      setCcProcessing(false);

      setPendingUser({
        email,
        first_name: firstName,
      });
    } catch (error: any) {
      setErrorMsg(error.message);
      setCcProcessing(false);
      console.log('error signing up:', error);
    }
  };

  const toggleCreditCardForm = () => {
    setIsCreditCardFormVisible((state) => !state);
  };

  const paypalPayMutation = useMutation({
    mutationFn: paypalSubscriptionCharge,
    mutationKey: ['paypalSubscriptionCharge'],
    onError: (error) => {
      console.error('Error paying with paypal:', error);
    },
    onSuccess: (data) => {
      const approveLink = data.links.find(
        (link: { rel: string }) => link.rel === 'approve'
      );
      if (approveLink) {
        window.location.href = approveLink.href;
      }
    },
  });

  const onPayPalPay = async (event: any) => {
    event.preventDefault();
    setIsCreditCardFormVisible(false);
    paypalPayMutation.mutate({
      email: pendingUser?.email!,
      name: pendingUser?.first_name!,
      plan_id: paypal_plan_id,
      quantity,
      multiplier,
    });
  };

  const totalPrice = useMemo(() => {
    if (!stripePromoCode) {
      return parseFloat(product_price.toFixed(2));
    }

    if (stripePromoCode?.coupon?.amount_off) {
      const discountedPrice = Math.max(
        0,
        product_price - stripePromoCode.coupon.amount_off
      );

      return parseFloat(discountedPrice.toFixed(2));
    }

    if (stripePromoCode?.coupon?.percent_off) {
      const discountedPrice =
        product_price * (1 - stripePromoCode.coupon.percent_off / 100);
      return parseFloat(discountedPrice.toFixed(2));
    }

    return parseFloat(product_price.toFixed(2));
  }, [product_price, stripePromoCode]);

  return (
    <div className='choose-form row m0' id='paypal-regn'>
      <Transition
        show={pendingUser === null}
        enter='ease-out duration-300'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <div id='js-product-info' className='product-info'>
          <form method='POST' onSubmit={onFirstStepSubmit}>
            <div className=''>
              <p className='m-b-80 text-center'>
                Hi there 👋 <br />
                Please create an account to get started. <br />
                It's FREE!
              </p>
              <div className='form-group'>
                <input
                  type='text'
                  name='first_name'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                      isFormInvalid &&
                      !validationRef.current.fields['First Name'] &&
                      'invalid'
                    }`}
                  placeholder='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {validationRef.current.message(
                  'First Name',
                  firstName,
                  'required',
                  {
                    className: 'text-red-500 text-left',
                  }
                )}
              </div>
              <div className='form-group'>
                <input
                  type='email'
                  name='email'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                      isFormInvalid &&
                      !validationRef.current.fields['Email Address'] &&
                      'invalid'
                    }`}
                  placeholder='Email Address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {validationRef.current.message(
                  'Email Address',
                  email,
                  'required|email',
                  { className: 'text-red-500 text-left' }
                )}
              </div>

              <div className='form-group'>
                <input
                  type='password'
                  name='password'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                      isFormInvalid &&
                      !validationRef.current.fields['Password'] &&
                      'invalid'
                    }`}
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {validationRef.current.message(
                  'Password',
                  password,
                  'required|min:6',
                  { className: 'text-red-500 text-left' }
                )}
              </div>

              <div className='form-group'>
                <input
                  type='password'
                  name='confirm_password'
                  className={`block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none ${
                isFormInvalid &&
                !validationRef.current.fields['Confirm Password'] &&
                'invalid'
              }`}
                  placeholder='Password (repeat)'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {validationRef.current.message(
                  'Confirm Password',
                  confirmPassword,
                  `required|min:6|in:${password}`,
                  { className: 'text-red-500 text-left' }
                )}
              </div>
            </div>
            {errorMsg !== '' && (
              <p className='text-red-500 text-left'>{errorMsg}</p>
            )}
            <div className='text-sm font-light text-left mt-4'>
              {ccProcessing && (
                <button
                  className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  id='next-personal'
                >
                  <i className='fa fa-spin fa-spinner'></i> Processing...
                </button>
              )}
              {!ccProcessing && (
                <input
                  type='submit'
                  className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  value='Sign Up'
                />
              )}
            </div>
          </form>
        </div>
      </Transition>

      <Transition
        show={pendingUser !== null}
        enter='ease-out duration-300'
        enterFrom='opacity-0 scale-95'
        enterTo='opacity-100 scale-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100 scale-100'
        leaveTo='opacity-0 scale-95'
      >
        <div className='' id='js-personal-info'>
          <div className='text-left'>
            {!haveAlreadyBought && (
              <h3 className='text-2xl'>Start your free trial of UTM Simple</h3>
            )}
            <h4 className='text-xl mt-2'>
              Get full access to UTM Simple {title}{' '}
              {!haveAlreadyBought && <span>free for 7 days!</span>}
            </h4>
            <p className='mt-1'>
              Try our UTM Simple tracking with no risk and no commitment. We
              won't charge you until after your free trial and you can cancel
              anytime.
            </p>

            {!haveAlreadyBought && (
              <p className='text-lime-600 fw-500 mt-1'>Today's charge: $0</p>
            )}
            {!haveAlreadyBought && (
              <span className='small'>
                Then a total of ${totalPrice} per month beginning on{' '}
                {dateReformat(trial_end)}.
              </span>
            )}
            {haveAlreadyBought && (
              <span className='small'>
                Your card will be charged{' '}
                <span className='font-semibold'>${totalPrice}/month</span>{' '}
                beginning today.
              </span>
            )}
          </div>

          <div className='flex mt-5'>
            <div className='basis-1/2 p-2 flex items-center justify-center'>
              {paypalPayMutation.isPending && (
                <ImSpinner2 className='animate-spin text-gray-400' size={32} />
              )}
              {!paypalPayMutation.isPending && (
                <button
                  className='w-full rounded-[30px] bg-[#ffe457] hover:text-white block text-black hover:bg-[#374048] text-[26px] font-semibold leading-[70px]'
                  id='next-personal'
                  onClick={onPayPalPay}
                >
                  <i className='fa fa-paypal'></i>&nbsp; PAYPAL
                </button>
              )}
            </div>
            <div className='basis-1/2 p-2'>
              <button
                className={`w-full rounded-[30px] hover:text-white block hover:bg-[#374048] text-[26px] font-semibold leading-[70px] ${
                  isCreditCardFormVisible
                    ? 'text-white bg-[#374048]'
                    : 'bg-[#ffe457] text-black'
                }`}
                id='next-personal'
                onClick={toggleCreditCardForm}
              >
                <i className='fa fa-credit-card'></i>&nbsp; CREDIT CARD
              </button>
            </div>
          </div>

          <div
            className={`mt-2 ${isCreditCardFormVisible ? 'block' : 'hidden'}`}
          >
            <Elements stripe={promise}>
              <StripeCheckoutForm choseCreditCard={isCreditCardFormVisible} />
            </Elements>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default PaymentForm;
