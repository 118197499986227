import { useState } from 'react';
import StripePlan from './StripePlan';
import PaypalPlan from './PaypalPlan';
import { testStripeSubString } from '../../../utils';
import { getPrices } from '../../../consts';
import { dateReformat } from '../../Common';
import Modal from '../../Modal';
import { PlanAccordion } from './PlanAccordion';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAccountInfo } from './account-info.api';
import { upgradePreview } from './upgrade-preview.api';
import { MINUTE } from '../../../consts/time';
import { LoadingSpinner } from '../icons/loading-spinner';
import { loadStripe } from '@stripe/stripe-js';

const prices = getPrices();

declare global {
  interface Window {
    HandL: any;
  }
}

const { HandL } = window;

export default function Plans() {
  const [route, setRoute] = useState('');
  const [upgradePlan, setUpgradePlan] = useState<any>('');
  const [currentSub, setCurrentSub] = useState<any>({});
  const [currentMeta, setCurrentMeta] = useState<any>({});
  const [upgradePayload, setUpgradePayload] = useState<any>({});
  const [expandedSubscription, setExpandedSubscription] = useState<string>('');

  const [showPlanCancelModal, setShowPlanCancelModal] = useState(false);

  const queryClient = useQueryClient();

  const expandAccordionForPlanHandler = (item_id: string) => {
    if (item_id === expandedSubscription) {
      setExpandedSubscription('');
      return;
    }

    setExpandedSubscription(item_id);
  };

  const upgradePreviewMutation = useMutation({
    mutationKey: ['upgradePreview'],
    mutationFn: (body: any) => {
      return upgradePreview(body);
    },
    onSuccess: async (data) => {
      console.log(data);

      if (pay_type === 'paypal' && data.links) {
        let approveLink = data.links.filter(
          (link: { rel: string }) => link.rel === 'approve'
        );

        window.location = approveLink[0].href;
      }

      if (
        pay_type === 'stripe' &&
        data.status !== 'draft' &&
        data.status !== 'canceled'
      ) {
        setUpgradePayload(data);
        if (!data.error) {
          setRoute('UpgradeSuccess');

          if (data.client_secret) {
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY!);
            await stripe!.confirmCardPayment(data.client_secret);
          }
        } else {
          setRoute('UpgradeFailed');
        }
      }

      queryClient.invalidateQueries({
        queryKey: ['accountInfo'],
      });
    },
    onError: (error) => {
      setUpgradePayload(error);
      setRoute('UpgradeFailed');
    },
  });

  const {
    data: accountInfo,
    isLoading: isAccountInfoLoading,
    isRefetching: isAccountInfoRefetching,
  } = useQuery({
    queryKey: ['accountInfo'],
    queryFn: getAccountInfo,
    staleTime: 10 * MINUTE,
  });

  const { subscriptions, metas, plans } = accountInfo || {};

  const pay_type = testStripeSubString(currentSub.id) ? 'stripe' : 'paypal';

  const onStripePlanUpgrade = (subscription: any, meta: any, plan: any) => {
    setRoute('upgradePreview');
    upgradePreviewMutation.mutate({
      subID: subscription.id,
      priceId: (prices as any)[plan].stripe,
      pay_id: 'stripe',
      type: 'preview',
      membership: true,
      marketing: HandL?.getAll(),
    });

    setUpgradePlan((prices as any)[plan]);
    setCurrentMeta(meta);
    setCurrentSub(subscription);
    console.log(subscription, meta);
  };

  const onPaypalPlanUpgrade = (subscription: any, meta: any, plan: any) => {
    if (plan === '') {
      setRoute('');
      return;
    }
    setRoute('upgradePaypal');
    setUpgradePlan((prices as any)[plan]);
    setCurrentMeta(meta);
    setCurrentSub(subscription);
  };

  const renew_sub = upgradePreviewMutation.data?.lines?.data?.filter(
    (item: any) => item.type === 'subscription'
  )[0];

  const cancelRevert = () => {
    setShowPlanCancelModal(false);
    setRoute('');
  };

  const cancelSubApproved = async () => {
    setShowPlanCancelModal(false);
    upgradePreviewMutation.mutate({
      pay_id: pay_type,
      type: 'cancel',
      subID: currentSub.id,
      membership: true,
      marketing: HandL?.getAll(),
    });
  };

  const onUpgrade = async () => {
    const priceID =
      pay_type === 'stripe' ? upgradePlan.stripe : upgradePlan.paypal;

    setRoute('Upgrade');
    upgradePreviewMutation.mutate({
      pay_id: pay_type,
      type: 'update',
      subID: currentSub.id,
      priceId: priceID,
      license_key: currentMeta.license_key,
      membership: true,
      marketing: HandL?.getAll(),
    });
  };

  const onPlanCancel = (subscription: any, meta: any) => {
    setShowPlanCancelModal(true);
    setCurrentMeta(meta);
    setCurrentSub(subscription);
  };

  if (isAccountInfoLoading) {
    return (
      <div id='license' className='mx-auto'>
        <div className='text-center'>
          <LoadingSpinner />
          <p>Please wait while we are pulling your account data...</p>
        </div>
      </div>
    );
  }

  if (!isAccountInfoLoading && !subscriptions?.length) {
    return <p>No plans found</p>;
  }

  const isPremiumSupportPlan = metas.some((meta: any) => meta.support_order_id);

  return (
    <>
      <div className='col-md-12 table-responsive shadow-md'>
        <table
          className='w-full text-sm text-left text-gray-500 rounded-lg my-3'
          style={{ tableLayout: 'fixed' }}
        >
          <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: isPremiumSupportPlan ? '260px' : '150px',
                }}
              >
                Plan
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: '150px',
                }}
              >
                Terms
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: '150px',
                }}
              >
                Status
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: '120px',
                }}
              >
                Start
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: '120px',
                }}
              >
                End
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
              >
                Payment Method
              </th>
              <th
                scope='col'
                className='py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: '250px',
                }}
              >
                Domain
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
                style={{
                  width: '160px',
                }}
              >
                Upgrade
              </th>
              <th
                scope='col'
                className='px-6 py-3 text-left text-sm font-semibold text-gray-900'
              >
                Cancel
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((item: any) => (
              <StipeOrPaypalRow
                key={item.id}
                item={item}
                metas={metas}
                plans={plans}
                onPlanCancel={onPlanCancel}
                onStripePlanUpgrade={onStripePlanUpgrade}
                onPaypalPlanUpgrade={onPaypalPlanUpgrade}
                expandAccordionForPlanHandler={expandAccordionForPlanHandler}
                expandedSubscription={expandedSubscription}
              />
            ))}
          </tbody>
        </table>
      </div>

      {(upgradePreviewMutation.isPending || isAccountInfoRefetching) && (
        <div className='mt-4'>
          <LoadingSpinner />
          <p className='text-center'>Please wait...</p>
        </div>
      )}

      {route === 'upgradePreview' && upgradePreviewMutation.error && (
        <div className='mt-4 text-center'>
          <div className=''>
            There has been some problem with your upgrade. Please contact
            support. <br />
            <code>{upgradePreviewMutation.error.message}</code>
          </div>
        </div>
      )}

      {route === 'upgradePreview' && upgradePreviewMutation.data && (
        <Modal
          isOpen={
            route === 'upgradePreview' &&
            upgradePreviewMutation.data &&
            !upgradePreviewMutation.isPending
          }
          title={
            <>
              You are upgrading your plan from {currentSub.plan.nickname} to{' '}
              {upgradePlan.title}
            </>
          }
          onClose={() => {
            setRoute('');
          }}
          className='p-8'
          maskClassName=''
          onAfterClosed={() => {}}
        >
          <div className='mt-2 text-right'>
            <br />
            <table className='text-sm text-left rtl:text-right text-gray-500 w-full'>
              <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                <tr>
                  <th scope='col' className='px-6 py-3'>
                    Description
                  </th>
                  <th scope='col' className='px-6 py-3'>
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {upgradePreviewMutation.data.lines.data.map((item: any) => (
                  <tr key={item.id} className='bg-white border-b'>
                    <td className='px-6 py-4'>{item.description}</td>
                    <td className='px-6 py-4'>
                      {parseInt(item.amount) < 0 ? '-' : ''} $
                      {Math.abs(item.amount) / 100}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th className='px-6 py-4'>Amount Due Now</th>
                  <th className='px-6 py-4'>
                    ${upgradePreviewMutation.data.amount_due / 100}
                  </th>
                </tr>
              </tfoot>
            </table>
            <div className='alert alert-info text-green-500' role='alert'>
              Your next renewal will be at ${renew_sub.amount / 100}/
              {renew_sub.price.recurring.interval} on{' '}
              {dateReformat(renew_sub.period.end * 1000)}
            </div>
            <div className='text-right mt-1'>
              <button
                className='mt-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
                onClick={cancelRevert}
              >
                Cancel
              </button>
              <button
                className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none'
                onClick={onUpgrade}
              >
                Upgrade
              </button>
              <p className='text-sm text-gray-600'>
                * We'll charge the credit card on file.
              </p>
            </div>
          </div>
        </Modal>
      )}

      {route === 'UpgradeFailed' && (
        <div className='text-red-600'>
          There has been some problem with your upgrade. Please contact support.{' '}
          <br />
          <code>{upgradePayload.error_message}</code>
        </div>
      )}

      {route === 'upgradePaypal' && (
        <Modal
          isOpen={route === 'upgradePaypal'}
          title={
            <div className='mb-10'>
              You are upgrading your plan from {currentSub?.plan?.nickname} to{' '}
              {upgradePlan.title}
            </div>
          }
          onClose={() => {
            setRoute('');
          }}
          className='p-8'
          maskClassName=''
          onAfterClosed={() => {}}
        >
          <div className='text-right'>
            <button
              className='mt-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
              onClick={cancelRevert}
            >
              Cancel
            </button>
            <button
              className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none'
              onClick={onUpgrade}
            >
              Upgrade
            </button>
          </div>
        </Modal>
      )}

      {showPlanCancelModal && (
        <Modal
          isOpen={showPlanCancelModal}
          title={
            <div className='mb-10'>
              Would you like to continue cancellation?
            </div>
          }
          onClose={() => setShowPlanCancelModal(false)}
          className='p-8'
          maskClassName=''
          onAfterClosed={() => {}}
        >
          <div className='text-blue-500 text-xl text-right' role='alert'>
            <section className='flex flex-col text-sm'>
              <span>
                * I accept that I'll lose access to UTM Simple tracking tool and
                all the tracking data will be lost.
              </span>
              <span>Would you like to continue cancellation?</span>
            </section>
            <div className='flex items-end justify-end mt-2'>
              <button
                className='text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none'
                onClick={cancelRevert}
              >
                No, stop
              </button>

              <button
                className='focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2'
                onClick={cancelSubApproved}
              >
                Yes, cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

const StipeOrPaypalRow = ({
  item,
  plans,
  metas,
  onPlanCancel,
  onPaypalPlanUpgrade,
  onStripePlanUpgrade,
  expandAccordionForPlanHandler,
  expandedSubscription,
}: {
  item: any;
  metas: any;
  plans: any[];
  onPlanCancel: any;
  onStripePlanUpgrade: any;
  onPaypalPlanUpgrade: any;
  expandedSubscription: string;
  expandAccordionForPlanHandler: (item_id: string) => void;
}) => {
  const meta = metas.find((m: any) => m.subscription === item.id);
  const [domain, setDomain] = useState<string>(meta.allowed_domains[0]);

  if (testStripeSubString(item.id)) {
    return (
      <>
        <tr key={item.id} className='-title border-b bg-white hover:bg-gray-50'>
          <StripePlan
            key={item.id}
            subscription={item}
            meta={metas.find((m: any) => m.subscription === item.id)}
            onPlanUpgrade={onStripePlanUpgrade}
            onPlanCancel={onPlanCancel}
            domain={domain}
            expandAccordionForPlanHandler={expandAccordionForPlanHandler}
          />
        </tr>
        <PlanAccordion
          item={item}
          domain={domain}
          setDomain={setDomain}
          expandedSubscription={expandedSubscription}
          meta={metas.find((m: any) => m.subscription === item.id)}
        />
      </>
    );
  }

  return (
    <>
      <tr key={item.id} className='-title border-b bg-white hover:bg-gray-50'>
        <PaypalPlan
          key={item.id}
          subscription={item}
          meta={metas.find((m: any) => m.subscription === item.id)}
          plan={plans.find((m: any) => m.id === item.plan_id)}
          onPlanUpgrade={onPaypalPlanUpgrade}
          onPlanCancel={onPlanCancel}
          domain={domain}
          expandAccordionForPlanHandler={expandAccordionForPlanHandler}
        />
      </tr>
      <PlanAccordion
        item={item}
        domain={domain}
        setDomain={setDomain}
        expandedSubscription={expandedSubscription}
        meta={metas.find((m: any) => m.subscription === item.id)}
      />
    </>
  );
};
