import { useEffect } from 'react';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import Terms from './components/Terms';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Home from './components/Home';
import Layout from './components/Layout';
import Privacy from './components/Privacy';
import Disclaimer from './components/Disclaimer';
import PaymentModal from './components/Payment/PaymentModal';

import ConfirmUser from './components/ConfirmUser';
import AccountInfos from './components/AccountInfos';
import Subscriptions from './components/AccountInfos/Subscriptions';
import Billings from './components/AccountInfos/Billings/Billings';
import MyAccounts from './components/AccountInfos/MyAccount/MyAccounts';
import ScheduleFrame from './components/ScheduleFrame';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAuthStore } from './features/auth/AuthStore';
import { MonthlyReport } from './components/AccountInfos/MonthlyReports/MonthlyReportsPage';
import SupportOffer from './components/SupportPackage/SupportOffer';
import Welcome from './components/SupportPackage/Welcome';

const { $crisp } = window;

const queryClient = new QueryClient();

const App = () => {
  let [searchParams, _] = useSearchParams();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const showLoginForm = useAuthStore((state) => state.showLoginForm);
  const setUserAttributes = useAuthStore((state) => state.setUserAttributes);
  const removeAuthenticatedUser = useAuthStore(
    (state) => state.makeIsAuthenticatedFalse
  );
  const makeUserAuthenticated = useAuthStore(
    (state) => state.makeUserAuthenticated
  );
  const setAuthUser = useAuthStore((state) => state.setAuthUser);
  const setPendingUser = useAuthStore((state) => state.setPendingUser);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const onInit = async () => {
      let advancedMatching: any = {};
      try {
        // if user is trying to confirm their account with the confirmation code
        // then we will not fetch the user attributes and set the user as authenticated
        // because there will be an error and in the catch block we will redirect the user to the home page
        // so that ConfirmUser popup would be shown
        if (
          location.pathname === '/confirm/' &&
          searchParams.get('confirmation_code') !== '' &&
          searchParams.get('client_id') !== '' &&
          searchParams.get('user_name') !== ''
        ) {
          return;
        }

        const user = await getCurrentUser();

        const user_attributes = await fetchUserAttributes();
        setUserAttributes(user_attributes);
        setAuthUser(user);
        makeUserAuthenticated();

        setPendingUser({
          first_name: user_attributes.given_name,
          email: user_attributes.email,
        });

        $crisp.push(['set', 'user:nickname', [user_attributes.given_name]]);
        $crisp.push(['set', 'user:email', [user_attributes.email]]);

        advancedMatching.em = user_attributes.email;
        advancedMatching.fn = user_attributes.given_name;
      } catch (error) {
        if (
          location.pathname === '/support-plan' ||
          location.pathname === '/welcome'
        ) {
          return;
        }

        removeAuthenticatedUser();
        navigate('/');
        console.log(error);
      }

      if (searchParams.get('login') === '1' && !isAuthenticated) {
        showLoginForm();
      }
    };
    onInit();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <PaymentModal />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/support-plan' element={<SupportOffer />} />
          <Route path='/welcome' element={<Welcome />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/confirm' element={<ConfirmUser />} />
          <Route path='/schedule' element={<ScheduleFrame />} />
          <Route path='/infos' element={<AccountInfos />}>
            <Route index element={<Subscriptions />} />
            <Route path='subscriptions' element={<Subscriptions />} />
            <Route path='billings' element={<Billings />} />
            <Route path='myaccounts' element={<MyAccounts />} />
            <Route path='monthly-reports' element={<MonthlyReport />} />
          </Route>
        </Routes>
      </Layout>

      <ReactQueryDevtools initialIsOpen={true} position='right' />
    </QueryClientProvider>
  );
};

export default App;
