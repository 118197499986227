import { Link, useNavigate } from 'react-router-dom';
import FlameIcon from '../../images/flame-icon.png';
import { useMutation } from '@tanstack/react-query';
import { buySupportPlan } from './support-plan.api';
import { ImSpinner2 } from 'react-icons/im';
import { useAuthStore } from '../../features/auth/AuthStore';
import { useEffect } from 'react';

type StripeResponse =
  | {
      status: 'succeeded';
    }
  | {
      next_action: {
        type: 'redirect_to_url';
        redirect_to_url: {
          url: string;
        };
      };
      status: 'requires_action';
    };

type PaypalResponse = {
  status: 'CREATED';
  id: string;
  links: {
    href: string;
    rel: string;
    method: string;
  }[];
};

type PaypalCaptureResponse = {
  status: 'COMPLETED';
  links: {
    href: string;
    rel: string;
    method: string;
  }[];
  payer: {
    address: {
      country_code: string;
    };
    email_address: string;
    payer_id: string;
  };
};

type StripeCaptureResponse = {
  status: 'succeeded';
  amount: number;
  customer: string;
  client_secret: string;
};

const SupportOffer = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const subscription_id = urlParams.get('subscription_id');
  const pay_id = urlParams.get('pay_id') as
    | 'stripe'
    | 'paypal'
    | 'paypal_capture'
    | 'stripe_capture';
  const main_subscription_id_after_during_paypal_capture = urlParams.get(
    'main_subscription_id'
  );
  const payment_intent = urlParams.get('payment_intent');
  const email = urlParams.get('email')!;
  const name = urlParams.get('name')!;

  const navigate = useNavigate();

  const pendingUser = useAuthStore((state) => state.pendingUser);

  const supportPlanMutation = useMutation({
    mutationFn: buySupportPlan,
    mutationKey: ['buySupportPlan'],
    onSuccess: (
      data:
        | StripeResponse
        | PaypalResponse
        | PaypalCaptureResponse
        | StripeCaptureResponse
    ) => {
      // Stripe
      if (data.status === 'requires_action') {
        window.location.href = data.next_action.redirect_to_url.url;
      }

      // Stripe || Stripe Capture
      if (data.status === 'succeeded') {
        console.log(data);
        navigate('/welcome?bought_support=true');
      }

      // Paypal
      if (data.status === 'CREATED') {
        const approvalUrl = data.links.find((link) => link.rel === 'approve');
        window.location.href = approvalUrl!.href;
      }

      // Paypal Capture
      if (pay_id === 'paypal_capture') {
        if (data.status === 'COMPLETED') {
          navigate('/welcome?bought_support=true');
        }
      }
    },
  });

  useEffect(() => {
    if (pay_id === 'paypal_capture' || pay_id === 'stripe_capture') {
      buySupportPlanForSubscription();
    }
  }, []);

  const buySupportPlanForSubscription = () => {
    const order_id = urlParams.get('token');

    const current_subscription_id =
      pay_id === 'paypal_capture' || pay_id === 'stripe_capture'
        ? main_subscription_id_after_during_paypal_capture
        : subscription_id;

    console.log('payment_intent', payment_intent);

    supportPlanMutation.mutate({
      pay_id,
      order_id,
      payment_intent,
      subscription_id: current_subscription_id!,
      email: pendingUser?.email ?? email,
      name: pendingUser?.first_name ?? name,
    });
  };

  return (
    <section className='mt-32 mb-4 mx-auto min-h-[700px] '>
      <div className='flex flex-col items-center gap-4 mx-auto'>
        <h2 className='font-semibold text-[#374048] text-3xl md:text-5xl min-[2000px]:text-6xl md:!leading-[3.5rem] min-[2000px]:!leading-[4.15rem]'>
          Congratulations and Thank You!
        </h2>

        <p className='text-base text-center sm:text-lg md:text-xl md:max-w-[980px]'>
          We appreciate your choice and faith in our product, UTM Simple. <br />{' '}
          You've made an excellent decision in choosing this powerful tool that
          is going to revolutionize your UTM tracking and lead attribution
          capabilities.
        </p>
      </div>

      <div className='flex flex-col gap-4 md:px-5 min-[2000px]:gap-8 md:max-w-[980px] mx-auto pt-5'>
        <div className='text-center sm:text-left'>
          <h3 className='mb-2.5 text-xl md:text-2xl font-medium flex items-center gap-x-2'>
            <span>
              <img src={FlameIcon} className='w-5' alt='Flame Icon' />
            </span>
            <span>One-Time Offer</span>
            <span>
              <img src={FlameIcon} className='w-5' alt='Flame Icon' />
            </span>
          </h3>
          <p className='text-base min-[2000px]:text-lg'>
            Before you start using our product, take advantage of our exclusive
            one-time offer! For just{' '}
            <span className='font-semibold text-xl'>$295</span>, let our team
            handle the complete onboarding and setup of UTM Simple for you. Save
            time and start enjoying the benefits immediately—don't miss out!
          </p>

          <div className='flex flex-col gap-3 my-5 sm:mt-3 sm:flex-row'>
            <Link
              className={`bg-red-500 rounded hover:bg-red-800 px-4 py-2 font-medium !text-white ext-sm md:text-base lg:text-lg disabled:cursor-wait disabled:bg-gray-400`}
              to={'/infos'}
            >
              Skip
            </Link>
            <button
              className={`bg-green-500 rounded hover:bg-green-800 px-4 py-2 font-medium text-white ext-sm md:text-base lg:text-lg disabled:cursor-wait disabled:bg-gray-400`}
              onClick={buySupportPlanForSubscription}
              disabled={supportPlanMutation.isPending}
            >
              <div className='flex gap-x-2'>
                {supportPlanMutation.isPending && (
                  <span>
                    <ImSpinner2 className='animate-spin text-white' size={24} />
                  </span>
                )}
                <span>Upgrade Now</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportOffer;
