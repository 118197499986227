import { NavLink } from 'react-router-dom';
import { PiStarFill } from 'react-icons/pi';
import { BsCreditCardFill } from 'react-icons/bs';
import { RiSettings4Fill } from 'react-icons/ri';
import { FaArrowTrendUp } from 'react-icons/fa6';

function AccountInfoSidebar() {
  return (
    <aside className='static left-0 z-40 w-64 min-h-[calc(100vh-240px)] h-full'>
      <div className='h-full px-3 py-4 overflow-y-auto bg-gray-50'>
        <ul className='space-y-2 font-medium'>
          <li>
            <NavLink
              to='/infos/subscriptions'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <PiStarFill size={24} />
              <span className='flex-1 ml-3 whitespace-nowrap'>
                Subscriptions
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/infos/billings'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <BsCreditCardFill size={20} />
              <span className='flex-1 ml-3 whitespace-nowrap'>Billings</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/infos/monthly-reports'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <FaArrowTrendUp size={22} />
              <span className='flex-1 ml-3 whitespace-nowrap'>
                Monthly Reports
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to='/infos/myaccounts'
              className={({ isActive }) =>
                `flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 cursor-pointer ${
                  isActive ? 'bg-gray-200' : ''
                }`
              }
            >
              <RiSettings4Fill size={20} />
              <span className='flex-1 ml-3 whitespace-nowrap'>My Account</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default AccountInfoSidebar;
