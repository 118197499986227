import { joinUrls } from '../../../utils/join-url';

const { HandL } = window;

const createStripeCustomer = async ({
  name,
  email,
}: {
  email: string;
  name: string;
}): Promise<StripeCustomer> => {
  const response = await fetch(
    joinUrls(process.env.REACT_APP_API_ENDPOINT!, 'http/charge'),
    {
      method: 'POST',
      body: JSON.stringify({
        pay_id: 'stripe',
        membership: true,
        type: 'cus',
        email: email,
        name: name,
        marketing: HandL?.getAll(),
      }),
    }
  );

  const data = await response.json();

  return data.customer;
};

export type StripeCustomer = {
  id: string;
  email: string;
  name: string;
  currency: string;
  created: number;
  invoice_prefix: string;
  metadata: Record<string, string>;
};

export { createStripeCustomer };
